
import "@/assets/styles/ItemDetailPage.scss";
import { Component, Vue, Watch } from "vue-property-decorator";
import SummaryTableComponent from "@/shared/components/ItemDetailComponent/Summary/Summary.vue";
import InfoComponent from "@/shared/components/ItemDetailComponent/Info/Info.vue";
import UnAuthorizedPage from "../UnAuthorizedPage.vue";
import LogComponent from "@/shared/components/ItemDetailComponent/Log/Log.vue";
import DownloadReports from "@/shared/components/ItemDetailComponent/DownloadReports/DownloadReports.vue";
import { signalRService } from "@/shared/services/websocket";
import { tsGraphQLService } from "@/shared/services/api/trouble-shooting-graphQL";
import {
  WORK_ITEM_SUMMARY,
  EXECUTION_STATUS,
  EXECUTION_REPORT_STATUS,
} from "@/shared/interface/workItemDetails";
import { WORKITEM_JOB_TYPE, SCHEDULE_TYPE } from "@/shared/interface/newItem";
import moment from "moment";
import { commonService } from "@/shared/services/api/common";
import reduce from "lodash.reduce";
import cloneDeep from "lodash.clonedeep";
import { APP_INFO } from "@/shared/interface/public";

@Component({
  name: "ItemDetailPage",
  components: {
    SummaryTableComponent,
    InfoComponent,
    LogComponent,
    UnAuthorizedPage,
    DownloadReports,
  },
})
export default class ItemDetailPage extends Vue {
  caseId = this["$route"].query.id;

  // periodically scheduled job must not null
  executionId: any = this["$route"].query.executionId;

  scheduledType = this["$route"].query.scheduledType;

  jobLogs = "";
  noLogs = false;
  isAuthorizedToViewLog = false;
  showDownloadDialog = false;
  appNameLoading = true;
  jobLogLoading = true;
  fileDownloadLoading = true;
  summaryData: Array<WORK_ITEM_SUMMARY> = [];
  executeReportStatus: EXECUTION_REPORT_STATUS =
    EXECUTION_REPORT_STATUS.nonExistent;
  jobType = this.$route.query.jobType || WORKITEM_JOB_TYPE.ondemand;
  serviceId = this.$route.query.serviceId || "noServiceId";
  jobStatus = "PENDING";
  filePathArr = [];
  userId: string;

  @Watch("summaryData", { deep: true, immediate: true })
  setupFilesForDownload(val: Record<string, any>): void {
    this.getUserId(val);

    if (val[0]?.filePath) this.filePathArr = val[0]?.filePath.split(";");
    this.showDownloadDialog =
      this.$route?.query?.downloadFiles === "true" &&
      this.filePathArr.length > 0;
  }

  async created(): Promise<any> {
    this.getItemDetailInfo();
  }

  displayDownloadDialogBox(): void {
    this.showDownloadDialog = true;
  }

  getUserId(summaryData): void {
    const userIDNameStr = process.env.VUE_APP_USERID_SYSNAME_MAP;
    if (userIDNameStr) {
      summaryData.forEach((tableData) => {
        const userIdSysNameMap = JSON.parse(userIDNameStr);
        const externalSystemIndex = userIdSysNameMap.findIndex(
          (item) =>
            item.userId.toLowerCase().trim() ===
            tableData.userId?.toLowerCase().trim()
        );
        if (externalSystemIndex !== -1)
          tableData.userId =
            userIdSysNameMap[externalSystemIndex].externalSystemName;
      });
    }
  }

  async getItemDetailInfo(): Promise<any> {
    const params = {
      caseId: this.caseId,
    } as any;
    const response = await tsGraphQLService.getAppNameForJob(params);
    if (response.data.hybris_workspace_item) {
      this.appNameLoading = false;
      const appName = response.data.hybris_workspace_item[0].app_name;
      //Display logs only for users authorized for the appName
      const currentApp = this.$store.state.appList.find(
        (item) => item.name === appName || item.label === appName
      );
      this.isAuthorizedToViewLog = !!currentApp;
      if (this.isAuthorizedToViewLog) {
        this.setCurrentApp(currentApp);
        await this.getWebsocketData();
        this.getWorkItemSummary();
      } else {
        localStorage.clear();
      }
    }
  }

  setCurrentApp(targetApp: APP_INFO): void {
    this.$store.commit("setCurrentApp", targetApp);
  }

  /**
   * get the summary of current work item
   * @returns void
   */
  async getWorkItemSummary(): Promise<any> {
    if (
      !this.executionId &&
      this.scheduledType === SCHEDULE_TYPE.periodically
    ) {
      this.executionId = "PLACEHOLDER";
    }

    const params = {
      caseId: this.caseId,
    } as any;
    this.executionId ? (params.executionId = this.executionId) : params;

    try {
      const response = await tsGraphQLService.getExecutionRecordByExecutionId(
        params
      );
      const executionData = response?.data;
      tsGraphQLService
        .getWorkItemSummary(this.caseId, this.executionId)
        .subscribe(
          (res) => {
            let data = res?.data?.hybris_workspace_item[0] || {};
            const logData = res?.data?.agent_job_log[0] || {};
            this.setSummaryData({ ...data, ...executionData }, logData);
            this.setExecuteReportStatus({ ...data, ...executionData });
            if (
              this.summaryData[0].status &&
              [
                EXECUTION_STATUS.succeed,
                EXECUTION_STATUS.failed,
                EXECUTION_STATUS.submitFailed,
                EXECUTION_STATUS.aborted,
                EXECUTION_STATUS.UnKnown,
                EXECUTION_STATUS.timedout,
              ].includes(this.summaryData[0].status as EXECUTION_STATUS)
            ) {
              this.determineJobStatus(logData.job_log);
              this.jobLogLoading = false;
            }
          },
          (error) => {
            this.jobLogLoading = false;
          }
        );
    } catch (err) {
      this.jobLogLoading = false;
    }
  }

  /**
   * get websocket message by monitorig the signalR targets
   * @returns void
   */
  async getWebsocketData(): Promise<any> {
    let executionId = this.executionId;
    let readRedis = true;
    if (!executionId) {
      const res = await tsGraphQLService.getExecutionIdByCaseId({
        caseId: this.caseId as string,
      });
      executionId = res?.data?.cx_iops_agent_job_execution?.[0]?.execution_id;
    } else {
      const res = await tsGraphQLService.getExecutionRecordByExecutionId({
        caseId: this.caseId as string,
        executionId: this.executionId as string,
      });
      const executionStatus =
        res?.data?.cx_iops_agent_job_execution?.[0]?.execution_status;
      readRedis = ![
        EXECUTION_STATUS.succeed,
        EXECUTION_STATUS.failed,
        EXECUTION_STATUS.submitFailed,
        EXECUTION_STATUS.aborted,
        EXECUTION_STATUS.UnKnown,
      ].includes(executionStatus);
    }

    let cachedData1: any = [],
      cachedData2: any = [];
    if (readRedis) {
      cachedData1 = await commonService.getCachedRealTimeLog(executionId);
      cachedData2 = await commonService.getCachedLogs(executionId);
    }
    const cachedData =
      cachedData1?.data?.data || cachedData2?.data?.data?.logs || [];

    if (readRedis && cachedData.length) {
      this.jobLogLoading = false;
    }

    const redisLog = [
      {
        code: 1,
        message: "get job log success",
        data: {
          entries:
            cachedData?.map((it) => ({
              node: "localhost",
              time: it.timestamp
                ? it.timestamp.split("T")[1].split("Z")[0].split(".")[0]
                : "",
              log: it.message,
              ...it,
            })) || [],
        },
      },
    ];

    this.determineRunningJobStatus(redisLog as any);

    signalRService.minitorSignalRTarget(
      this.caseId,
      this.serviceId,
      "onJobLog",
      (data) => {
        const record = JSON.parse(data);

        // when get first useful running logs from signalR
        if (record?.data?.entries?.length) {
          this.jobLogLoading = false;
        }
        redisLog.push(record);
        this.determineRunningJobStatus(redisLog as any);
      }
    );
    signalRService.minitorSignalRTarget(
      this.caseId,
      this.serviceId,
      "onJobStatus",
      (data) => {
        const { status } = typeof data === "string" ? JSON.parse(data) : data;
        if (
          [
            EXECUTION_STATUS.succeed,
            EXECUTION_STATUS.failed,
            EXECUTION_STATUS.submitFailed,
            EXECUTION_STATUS.aborted,
            EXECUTION_STATUS.timedout,
          ].includes(status)
        ) {
          this.getWorkItemSummary();
        }
      }
    );

    // listen to websockt in a native way
    signalRService.monitorWsChannel(this.caseId, this.executionId, "onJobLog");

    signalRService.monitorWsChannel(
      this.caseId,
      this.executionId,
      "onJobStatus",
      (data) => {
        const { status, logs, uuid } =
          typeof data === "string" ? JSON.parse(data) : data;

        // when get first useful running logs from websocket
        if (logs) {
          this.jobLogLoading = false;

          // below logic to remove duplicated logs from redis cached response
          const { message, timestamp } = logs[0];
          const pivot = redisLog[0].data.entries.findIndex(
            (it) => it.message === message && timestamp === it.timestamp
          );

          pivot === -1
            ? ""
            : (redisLog[0].data.entries = redisLog[0].data.entries.slice(
                0,
                pivot
              ));

          redisLog.push({
            code: 1,
            message: "get job log success",
            data: {
              entries: logs.map((it) => ({
                ...it,
                uuid,
                node: "localhost",
                time: it.timestamp.split("T")[1].split("Z")[0].split(".")[0],
                log: it.message,
              })),
            },
          });
          this.determineRunningJobStatus(redisLog as any);
        }
        if (status) this.jobStatus = status;
        if (
          [
            EXECUTION_STATUS.succeed,
            EXECUTION_STATUS.failed,
            EXECUTION_STATUS.submitFailed,
            EXECUTION_STATUS.aborted,
            EXECUTION_STATUS.UnKnown,
            EXECUTION_STATUS.timedout,
          ].includes(status)
        ) {
          this.getWorkItemSummary();
        }
      }
    );
  }

  /**
   * Determine job run or submit status
   * @param {JSON} data: log list
   * @returns void
   */
  determineJobStatus(data: Record<string, any> | any): void {
    data = data ? JSON.parse(data) : "";
    if (data && data.code !== undefined) {
      if (data.code === 1 || data.code === 0) {
        data.data.entries = data.data.entries?.map((it, idx) => ({
          ...it,
          logIdx: idx,
        }));
        this.jobLogs = data.data;
      } else {
        this.noLogs = true;
        this.jobLogs = "";
        this.$message.error(data.data.message);
      }
    } else {
      this.noLogs = true;
      this.jobLogs = data;
    }
  }

  /**
   * when job is running need fetch  both cached Logs and real-time logs
   */
  determineRunningJobStatus(list: []): void {
    const _list = cloneDeep(list);
    const transformData = reduce(
      _list,
      (pre, cur) => ({
        code: cur.code,
        message: cur.message,
        data: {
          ...cur.data,
          entries: [
            ...(pre.data?.entries?.map((it, idx) => ({
              ...it,
              logIdx: it.uuid || idx,
            })) || []),
            ...(cur.data?.entries?.map((it, idx) => ({
              ...it,
              logIdx: it.uuid || idx,
            })) || []),
          ],
        },
      }),
      {}
    );

    if (transformData && transformData.code !== undefined) {
      if (transformData.code === 1 || transformData.code === 0) {
        this.jobLogs = transformData.data;
      } else {
        this.noLogs = true;
        this.jobLogs = "";
        this.$message.error(transformData.data.message);
      }
    } else {
      this.noLogs = true;
      this.jobLogs = transformData;
    }
  }

  /**
   * Set the summary data format
   * @param {Object} data: the work item data fetch from db table hybris_workspace_item
   * @param {Object} logData: the log object fetch from db table agent_job_log
   * @returns void
   */
  setSummaryData(
    data: Record<string, any>,
    logData: Record<string, any>
  ): void {
    const cx_job_executions = data?.cx_iops_agent_job_execution?.[0] || {};
    let summaryItem: WORK_ITEM_SUMMARY = {
      status: cx_job_executions.execution_status,
      nodeStatus: cx_job_executions.node_status || "-",
      startTime: cx_job_executions.run_start_time
        ? moment(cx_job_executions.run_start_time).format("YYYY-MM-DD HH:mm:ss")
        : "-",
      endTime: cx_job_executions.run_end_time
        ? moment(cx_job_executions.run_end_time).format("YYYY-MM-DD HH:mm:ss")
        : "-",
      userId: data.owner,
      executionId: logData ? logData.execution_id : "",
      scheduleType: data.schedule_type || "",
    };
    if (
      cx_job_executions.file_path &&
      typeof cx_job_executions.file_path === "string"
    ) {
      summaryItem.filePath = cx_job_executions.file_path;
    } else {
      if (summaryItem.filePath) delete summaryItem.filePath;
    }
    this.summaryData = [summaryItem];
  }

  /**
   * set the execution report status
   * used for report download
   * @param {Object} data: the work item data fetch from db table hybris_workspace_item
   * @returns void
   */
  setExecuteReportStatus(data: Record<string, any>): void {
    const cx_job_executions = data?.cx_iops_agent_job_execution?.[0] || {};
    if (
      cx_job_executions.execution_status !== EXECUTION_STATUS.succeed ||
      !cx_job_executions.file_path ||
      typeof cx_job_executions.file_path !== "string"
    ) {
      this.executeReportStatus = EXECUTION_REPORT_STATUS.nonExistent;
    } else {
      this.executeReportStatus = EXECUTION_REPORT_STATUS.normal;
    }
  }
}
